import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@mui/material';

import {Card, CardContent, CardMedia} from '@mui/material';
import { Button, CardActionArea, CardActions } from '@mui/material';

import {Container, Row, Col} from 'react-bootstrap';


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


export default function History() {
	const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
 };

 const handleChangeIndex = (index: number) => {
    setValue(index);
 };

        return (
            <div id='history' className="bg-dark d-flex flex-wrap container-fluid d-flex justify-content-center align-items-center py-5 px-2">
			<div className="container">
			
				<Box className="bg-darker text-light" >
			    <AppBar position="static">
			        <Tabs
					value={value}
					onChange={handleChange}
					TabIndicatorProps={{
					style: {
						backgroundColor: "red",
						height: "5px"
					}
					}}
					textColor="inherit"
					variant="fullWidth"
					className="bg-warning text-dark"
			        >
					<Tab className="fw-bold" label="Academic Experience" {...a11yProps(0)} />
					<Tab className="fw-bold" label="Work Experience" {...a11yProps(1)} />
					<Tab className="fw-bold" label="Training - Certification" {...a11yProps(2)} />
					<Tab className="fw-bold" label="Others" {...a11yProps(3)} />
			        </Tabs>
			    </AppBar>
					<TabPanel value={value} index={0} dir={theme.direction} className='d-flex justify-content-center align-items-center'>
					<Container className='p-3 d-flex justify-content-center align-items-center'>
						<Row >
							

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-4.fna.fbcdn.net/v/t39.30808-6/329405354_936706650698273_2865547712163834085_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeGQvZP2_KcY17PjybAub1Ea_ACZf7AgbgH8AJl_sCBuATysIXY1_13FP7_6LMEqFRtF507ZDo_1qu_Eh9tbQaa_&_nc_ohc=D1JIK3DMTbIQ7kNvgEYhCU0&_nc_ht=scontent.fcrk1-4.fna&oh=00_AYChRQAzx5ZkSGoayIidrLuV4BjzC5Wk9Fg3jKQiD7Xp6A&oe=66A2A164"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold  text-warning'>
											San Luis National High School
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>Batch 2016</li>
												<li>Gawad Parangal Awardee</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-5.fna.fbcdn.net/v/t39.30808-6/362675101_750744020185357_2754314453438385283_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeFA1JiUsprCNNd0iaZFm-33SZeEa9Y3w2NJl4Rr1jfDY5z4qBRGzUAamt1VjXXmmC3QmbNDpaSV-wY12JzfQYEw&_nc_ohc=Em3P_AH_UK8Q7kNvgFQd72Y&_nc_ht=scontent.fcrk1-5.fna&oh=00_AYC4N-u4lL9tfRyiBlmxmMeGBPtpWMvzCnDt9sW3-4UVkg&oe=66A29BA2"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h6" component="div" className='font-weight-bold  text-warning'>
											Saint Aloysius Gonzaga Senior High School
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>Batch 2018 - STEM Strand</li>
												<li>With Honors</li>
												<li>Leadership Awardee</li>
												<li>Best Capstone Awardee</li>
												<li>Alumni Association President</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-3.fna.fbcdn.net/v/t39.30808-6/347842837_1359479261577006_7524738797845851369_n.png?_nc_cat=106&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeE2S2fUfOpeHLlHhvgoHQ9NI4nEdtCqiD0jicR20KqIPTB3pK4bhWr_M988yccEyEOEwGUhaZSO4GGh__QD0gak&_nc_ohc=_KZKDzTHEtUQ7kNvgGGxD6t&_nc_ht=scontent.fcrk1-3.fna&oh=00_AYDwn3oWpEhuYRnqLTBUSzogSwpJCBuo_TSB_NvduI3ESQ&oe=66A2A12C"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold  text-warning'>
											Holy Cross College - Pampanga
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>Batch 2022</li>
												<li>Bachelor of Science in Information Technology</li>
												<li>IRCITE - International Research Conference for IT Education </li>
													<ul>
														<li>Capstone Presenter</li>
													</ul>
												<li>Dean's and President's Lister</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0 '>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-1.fna.fbcdn.net/v/t39.30808-6/357368000_677877627704058_7382050720025723612_n.jpg?_nc_cat=1&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeFvf8pdU6OyjEzOvKIhT19VtBMZZ5qRm4-0ExlnmpGbj1qE2PhyZEI4ebKIjvNkbt9AwdeAKJllPBZPMaPQkb_4&_nc_ohc=Bj-_Atfn1igQ7kNvgHWQE3i&_nc_ht=scontent.fcrk1-1.fna&oh=00_AYDvaaWyu5nnk5TKd-BstQWWcdDMTv7CCLfuVxpPAbSezg&oe=66A27379"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold text-warning'>
											Our Lady Of Fatima University
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>27 Units Earned - Ongoing</li>
												<li>Master in Information Technology</li>
													<ul>
														<li>Systems Administration</li>
													</ul>
												<li>Thesis/Capstone Project Making</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>
						</Row>
					</Container>
			        </TabPanel>
					
			        <TabPanel value={value} index={1} dir={theme.direction} className='d-flex justify-content-center align-items-center'>
			        	<Container className='p-3 d-flex justify-content-center align-items-center'>
						<Row >
							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-3.fna.fbcdn.net/v/t39.30808-6/347842837_1359479261577006_7524738797845851369_n.png?_nc_cat=106&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeE2S2fUfOpeHLlHhvgoHQ9NI4nEdtCqiD0jicR20KqIPTB3pK4bhWr_M988yccEyEOEwGUhaZSO4GGh__QD0gak&_nc_ohc=_KZKDzTHEtUQ7kNvgGGxD6t&_nc_ht=scontent.fcrk1-3.fna&oh=00_AYDwn3oWpEhuYRnqLTBUSzogSwpJCBuo_TSB_NvduI3ESQ&oe=66A2A12C"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold  text-warning'>
											On-The-Job Training
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>700 Hours</li>
												<li>Holy Cross College</li>
													<ul>
														<li>MIS Office</li>
													</ul>
												<li>Computer Repair</li>
												<li>Network and Cable Installation</li>
												<li>Front-End Web Develepment Design</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-5.fna.fbcdn.net/v/t39.30808-6/362675101_750744020185357_2754314453438385283_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeFA1JiUsprCNNd0iaZFm-33SZeEa9Y3w2NJl4Rr1jfDY5z4qBRGzUAamt1VjXXmmC3QmbNDpaSV-wY12JzfQYEw&_nc_ohc=Em3P_AH_UK8Q7kNvgFQd72Y&_nc_ht=scontent.fcrk1-5.fna&oh=00_AYC4N-u4lL9tfRyiBlmxmMeGBPtpWMvzCnDt9sW3-4UVkg&oe=66A29BA2"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h6" component="div" className='font-weight-bold  text-warning'>
											St. Aloysius Gonzaga Academy, Inc.
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>1 Semester</li>
												<li>Senior High School Instructor</li>
												<li>Prefect of Discipline</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							

							<Col  lg={3} className='my-3 my-lg-0 '>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-3.fna.fbcdn.net/v/t39.30808-6/347842837_1359479261577006_7524738797845851369_n.png?_nc_cat=106&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeE2S2fUfOpeHLlHhvgoHQ9NI4nEdtCqiD0jicR20KqIPTB3pK4bhWr_M988yccEyEOEwGUhaZSO4GGh__QD0gak&_nc_ohc=_KZKDzTHEtUQ7kNvgGGxD6t&_nc_ht=scontent.fcrk1-3.fna&oh=00_AYDwn3oWpEhuYRnqLTBUSzogSwpJCBuo_TSB_NvduI3ESQ&oe=66A2A12C"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold text-warning'>
											Holy Cross College - Pampanga
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>2022 - Present</li>
												<li>Part-Time College IT Instructor</li>
													<ul>
														<li>SECLS Department</li>
													</ul>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://assets.zuitt.co/assets/zuitt/zuittweb_tn.jpg"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold  text-warning'>
											Zuitt Coding Bootcamp
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>2022-2024</li>
												<li>Jr IT Instructor</li>
												<li>MERN Stack Jr IT Instructor</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>
						</Row>
					</Container>
			        </TabPanel>

			        <TabPanel value={value} index={2} dir={theme.direction} className='d-flex justify-content-center align-items-center'>
			        					<Container className='p-3 d-flex justify-content-center align-items-center'>
						<Row >
							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg8Xb0MX5XeAQImBJVP6u2JXs_4Cz4fF-mgS62DikKQ15KiRaZLqI0ICp_VrB9sJNxwAI5C4ueO3WYPlm1h45xKiqc_bTlQWKzeA1yj5Sp8klBQIDM3ERfGUgXt_wYGVU4MKhCOgPwBm67a0KihTgD682WGCEP_DA5ticRsSz381OxLTJKYXwsaqkHq3qA/s1600/marc-2024-cse-ppt-results.jpg"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold  text-warning'>
											CSE - Career Service Exam
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>Professional Level - Passed</li>
												<li>Angeles City, Pampanga</li>
												<li>August 2023</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-5.fna.fbcdn.net/v/t39.30808-6/347828435_1380249125883244_4197888429018957079_n.png?_nc_cat=105&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeFaPaBPgKitK4H-BiCyXF67BdE7VqJC5V4F0TtWokLlXryv6Hcyd8URSX88gU2Id031I1AF9edFNp8w8Yxn_SdA&_nc_ohc=BYq6zCu1eHUQ7kNvgEo7iEX&_nc_ht=scontent.fcrk1-5.fna&oh=00_AYDr6i6OlLPv04M4Zcd3Zb1aGI7iCzQgBF6m_4N19LsUCQ&oe=66A295B7"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h6" component="div" className='font-weight-bold  text-warning'>
											Bayan Academy Web Development Bootcamp
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>February 9 - 28, 2022</li>
												<li>Intermediate Level Certification</li>
												<li>React.js - Frontend</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							

							<Col  lg={3} className='my-3 my-lg-0 '>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://scontent.fcrk1-5.fna.fbcdn.net/v/t39.30808-6/347828435_1380249125883244_4197888429018957079_n.png?_nc_cat=105&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeFaPaBPgKitK4H-BiCyXF67BdE7VqJC5V4F0TtWokLlXryv6Hcyd8URSX88gU2Id031I1AF9edFNp8w8Yxn_SdA&_nc_ohc=BYq6zCu1eHUQ7kNvgEo7iEX&_nc_ht=scontent.fcrk1-5.fna&oh=00_AYDr6i6OlLPv04M4Zcd3Zb1aGI7iCzQgBF6m_4N19LsUCQ&oe=66A295B7"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h6" component="div" className='font-weight-bold  text-warning'>
											Bayan Academy Web Development Bootcamp
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>April 11 - May 2, 2022</li>
												<li>Advanced Level Certification</li>
												<li>React.js - Frontend</li>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light card-height text-center'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://assets.zuitt.co/assets/zuitt/zuittweb_tn.jpg"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold  text-warning'>
											Zuitt Coding Bootcamp
										</Typography>
										<Typography variant="body2">
											<ul className='text-left mt-4'>
												<li>August 1 - September 23, 2022</li>
												<li>3 Level Cetification - MERN STACK</li>
													<ul>
														<li>Front-End</li>
														<li>Back-End</li>
														<li>Full Stack</li>
													</ul>
												<li>Technologies used:</li>
													<ul>
														<li>Git</li>
														<li>HTML, CSS, BOOTSTRAP</li>
														<li>JavaScript</li>
														<li>Node.js and Express.js</li>
														<li>MongoDB/Heroku</li>
														<li>React.js</li>
													</ul>
											</ul>
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>
						</Row>
					</Container>
			        </TabPanel>

			        <TabPanel value={value} index={3} dir={theme.direction} className='d-flex justify-content-center align-items-center'>
			        					<Container className='p-3 d-flex justify-content-center align-items-center'>
						<Row >
							<Col  lg={3} className='my-3 my-lg-0 '>
								<Card  className='bg-dark text-light'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://img.freepik.com/free-photo/dragons-fantasy-artificial-intelligence-image_23-2150400894.jpg?w=1380&t=st=1685380545~exp=1685381145~hmac=d6b7f106343d382225acc95de3a74a7d7d11cabfc14255bb84e194c9717b2421"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold'>
											Lizard
										</Typography>
										<Typography variant="body2">
											Lizards are a widespread group of squamate reptiles, with over 6,000
											species, ranging across all continents except Antarctica
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://img.freepik.com/free-photo/dragons-fantasy-artificial-intelligence-image_23-2150400894.jpg?w=1380&t=st=1685380545~exp=1685381145~hmac=d6b7f106343d382225acc95de3a74a7d7d11cabfc14255bb84e194c9717b2421"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold'>
											Lizard
										</Typography>
										<Typography variant="body2">
											Lizards are a widespread group of squamate reptiles, with over 6,000
											species, ranging across all continents except Antarctica
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://img.freepik.com/free-photo/dragons-fantasy-artificial-intelligence-image_23-2150400894.jpg?w=1380&t=st=1685380545~exp=1685381145~hmac=d6b7f106343d382225acc95de3a74a7d7d11cabfc14255bb84e194c9717b2421"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold'>
											Lizard
										</Typography>
										<Typography variant="body2">
											Lizards are a widespread group of squamate reptiles, with over 6,000
											species, ranging across all continents except Antarctica
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>

							<Col  lg={3} className='my-3 my-lg-0'>
								<Card  className='bg-dark text-light'>
									<CardActionArea>
										<CardMedia
										component="img"
										height="140"
										image="https://img.freepik.com/free-photo/dragons-fantasy-artificial-intelligence-image_23-2150400894.jpg?w=1380&t=st=1685380545~exp=1685381145~hmac=d6b7f106343d382225acc95de3a74a7d7d11cabfc14255bb84e194c9717b2421"
										alt="green iguana"
										/>
										<CardContent className='text-light'>
										<Typography gutterBottom variant="h5" component="div" className='font-weight-bold'>
											Lizard
										</Typography>
										<Typography variant="body2">
											Lizards are a widespread group of squamate reptiles, with over 6,000
											species, ranging across all continents except Antarctica
										</Typography>
										</CardContent>
									</CardActionArea>
									
								</Card>
								
							</Col>
						</Row>
					</Container>
			        </TabPanel>

			    </Box>
			</div>
		</div>
        );
    }